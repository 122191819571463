<template>
  <div>
    <admin-title title="Orders" class="mb-n5">
      <template #actions>
        <v-btn :to="{ name: 'CreateOrder' }" color="white primary--text" class="input-shadow" small>Create An Order</v-btn>
      </template>
    </admin-title>

    <v-tabs
      v-model="tab"
      color="primary"
      slider-color="primary"
      class="request-tabs"
    >
      <v-tab :to="{ name: 'InProgressOrders' }" exact>
        <span class="slider-title" >In Progress</span>
      </v-tab>
      <v-tab :to="{ name: 'CompletedOrders' }">
        <span class="slider-title">Completed</span>
      </v-tab>
      <v-tab v-if="$store.getters['orders/canManage']" :to="{ name: 'OrderForms' }">
        <span class="slider-title">Forms</span>
      </v-tab>
      <v-tab v-if="$store.getters['orders/canManage']" :to="{ name: 'OrderTerms' }">
        <span class="slider-title">Terms</span>
      </v-tab>
      <v-tab v-if="$store.getters['orders/canManage']" :to="{ name: 'OrderCannedResponses' }">
        <span class="slider-title">Canned Responses</span>
      </v-tab>
    </v-tabs>

    <div class="mt-5">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      tab: null,
    }
  }
}
</script>